import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
  selector: 'cf-organization-avatar',
  standalone: true,
  imports: [AvatarComponent],
  template: `
    @if (hasLogo()) {
      <div
        class="tw:rounded tw:border tw:border-raven-10 tw:overflow-hidden tw:bg-white"
        [class]="sizeClass()"
      >
        <img
          [src]="logo()!"
          class="tw:object-contain tw:aspect-square tw:size-full"
          width="100%"
          height="100%"
          alt="Organization Logo"
        />
      </div>
    } @else {
      <cf-avatar
        [fallbackClass]="'tw:rounded'"
        [userName]="name()"
        [size]="size()"
        [rounded]="false"
      />
    }
  `,
  host: {
    class: 'block',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationAvatarComponent {
  logo = input<string>();
  name = input.required<string>();
  size = input<'xs' | 'sm' | 'lg' | 'xl'>('sm');

  hasLogo = computed(() => {
    const logoValue = this.logo();
    return typeof logoValue === 'string' && logoValue.length > 0;
  });

  sizeClass = computed(() => {
    switch (this.size()) {
      case 'xs':
        return 'tw:size-4.5';
      case 'sm':
        return 'tw:size-6';
      case 'lg':
        return 'tw:size-10';
      case 'xl':
        return 'tw:size-18';
      default:
        return 'tw:size-6';
    }
  });
}
